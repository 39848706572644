<template>
  <div class="page-content">
    <page-breadcrumb title="Native11 teachers" class="mb-2" />

    <data-table-ssr id="level_list" ref="level_list"
      :create-row-fn="createTeacher" :create_schema="create_schema"
      :get-list-fn="getList" :columns="level_fields"
      :to-edit-fn="toEditPage"
    />
  </div>
</template>

<script>
import { removeEmpty } from '@core/utils/index'
import service from '../service'
const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Name', field: 'name', input_type: 'text', validate: { required: true }, },
      { label: 'Subtile', field: 'subtitle', input_type: 'text' },
      { label: 'Description', field: 'description', input_type: 'ui-component' },
    ]
  }
];

const level_fields = [
  { label: 'Name', field: 'name' },
  { label: 'Subtile', field: 'subtitle' },
  { label: 'Description', field: 'description', display_type: 'translatable-text' },
  { label: 'Created At', field: 'createdAt', input_type: 'date' },
];

export default {
  data(){
    return{
      create_schema,
      level_fields,
      total: 0,
      editing_data: null,
    }
  },
  methods:{
    async createTeacher({ email }) {
      let new_item = await service.create({ email });
      return new_item;
    },
    async getList({ limit, page, query }) {
      query = removeEmpty(query);
      let response = await service.getList({
        query: encodeURIComponent(JSON.stringify(query)),
        page,
        limit,
      });
      let list = []; let total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;
      }
      return { list, total }
    },
    async toEditPage({ _id }) {
      this.$router.push({ name: 'stage_level-detail', params: { id: _id } });
    },
  }
}
</script>
